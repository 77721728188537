import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import styles from './error404Header.scss';

const Error404Header: FC = () => {
    return (
        <div className={styles.header} data-tn="error-404-header">
            <div className={styles.h1}>
                <span className={styles.headerParticle}>
                    <FormattedMessage
                        id="dbl.Error404Header.header_particle"
                        defaultMessage="Oops!"
                    />
                </span>
                <FormattedMessage
                    id="dbl.Error404Header.header"
                    defaultMessage="Your page is not available."
                />
            </div>
            <div className={styles.h2}>
                <FormattedMessage
                    id="dbl.Error404Header.subtitle"
                    defaultMessage="But thousands of spectacular items from the world's most prestigious dealers are."
                />
            </div>
        </div>
    );
};

export default Error404Header;
