import { type FC, type MouseEvent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';

import copy from './CategoryRoomsCopy.json';
import styles from './CategoryRooms.scss';

type RoomCopy = {
    type: string;
    imageUrl: string;
    url: string;
};

type Props = {
    onClick?: (e: MouseEvent, obj: { room: RoomCopy }) => void;
};

export const CategoryRooms: FC<Props> = ({ onClick = () => {} }) => {
    return (
        <>
            <div className={styles.roomHeader} data-tn="category-rooms">
                <FormattedMessage id="dbl.CategoryRooms.header" defaultMessage="Shop by Room" />
            </div>
            <div className={styles.roomList}>
                {copy.rooms.map(room => {
                    const imageStyle = { backgroundImage: `url("${room.imageUrl}")` };
                    return (
                        <div className={styles.room} key={room.url}>
                            <Link
                                href={room.url}
                                className={styles.roomLink}
                                dataTn="category-room-tile-link"
                                onClick={e => onClick(e, { room })}
                            >
                                <div className={styles.roomTitle}>
                                    <FormattedMessage
                                        id="dbl.CategoryRooms.title"
                                        defaultMessage={`
                                            {type, select,
                                                dining {Dining Room}
                                                kitchen {Kitchen}
                                                living {Living Room}
                                                other {Bedroom}
                                            }
                                        `}
                                        values={{ type: room.type }}
                                    />
                                </div>
                                <div className={styles.roomImage} style={imageStyle} />
                            </Link>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
